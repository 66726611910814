<template>
  <div>
    <SplitListView :show-bottom-sheet="showBottomSheet">
      <template v-slot:list>
        <ActionList
          :load-items-callback="getMessengerMessages"
          :empty-text="$t('noCombinationsFound')"
          :filters="filters"
          refreshable
        >
          <template v-slot:item="{ item }">
            <MessengerMessageListItem
              :message="item"
              @click="messageSelected"
            />
          </template>
        </ActionList>
      </template>
      <template v-slot:view>
        <MessengerMessageProfile
          v-if="selectedMessage !== null"
          :message="selectedMessage"
        />
      </template>
    </SplitListView>
  </div>
</template>

<script>
import ActionList from "@/components/common/list/ActionList"
import MessengerMessageListItem from "@/components/messages/MessengerMessageListItem"
import MessengerMessageProfile from "@/components/messages/MessengerMessageProfile"
import SplitListView from "@/components/layouts/SplitListView"

export default {
  name: "Messages",
  components: {SplitListView, MessengerMessageProfile, MessengerMessageListItem, ActionList},
  data() {
		return {
			selectedMessage: null,
			filters: [
				{
					key: 'state',
					label: this.$t('state'),
					type: 'select',
					value: null,
					items: ['delivered', 'pending'],
					operator: 'contains'
				},
				{
					key: 'namespace',
					label: this.$t('namespace'),
					type: 'select',
					value: null,
					items: ['email', 'printjob', 'sms'],
					operator: 'contains'
				},
				{
					key: 'destination',
					label: this.$t('sku'),
					type: 'text',
					value: null,
					operator: 'contains'
				},
				{
					key: 'uuid',
					label: this.$t('universalUniqueIdentifier'),
					type: 'text',
					value: null,
					operator: 'contains'
				}
			]
		}
  },
  computed: {
    showBottomSheet: {
      get() {
        return this.selectedMessage !== null
      },
      set(value) {
        this.selectedMessage = null
      }
    }
  },
  methods: {
    getMessengerMethodInstances() {
      this.$store.dispatch('getMessengerMethodInstances')
    },
    getMessengerMessages() {
      const startTimeInMillis = Date.now() - 1000 * 60 * 60 * 24 * 20
      const endTimeInMillis = Date.now()


      return this.$store.dispatch('getMessengerMessages', {startTimeInMillis, endTimeInMillis}).then(res => {
        return res
      })
    },
    messageSelected(m) {
      this.selectedMessage = m
    }
  },
  mounted() {
    this.getMessengerMethodInstances()
  }
}
</script>

<style scoped>

</style>
