<template>
  <v-list-item
    @click="messageSelected"
    link
  >
    <v-list-item-content>
      <v-list-item-title class="text-capitalize">
        {{ formatNameSpace(message.namespace) }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ message.destination }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <div class="text-right text-capitalize text--white">
        <v-chip
          dark
          :color="stateColor"
        >
          {{ message.state }}
        </v-chip>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MessengerMessageListItem",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  methods: {
    messageSelected() {
      this.$emit('click', this.message)
    },
    formatNameSpace(namespace) {
      if(namespace.toLowerCase() === 'sms') {
        return this.$t('SMS')
      }
      return namespace
    }
  },
  computed: {
    stateColor() {
      switch (this.message.state) {
        case 'delivered':
          return 'success'
        case 'pending':
          return 'light-blue'
        default:
          return 'primary'
      }
    }
  }
}
</script>

<style scoped>

</style>
